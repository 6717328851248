
import { defineComponent, onMounted } from "vue";
import UserDetailsTable from "@/components/widgets/tables/UserDetailsTable.vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";

export default defineComponent({
  name: "user-details",
  components: {
    UserDetailsTable,
  },
  setup() {
    onMounted(() => {
      setCurrentPageBreadcrumbs("Details", ["Users"]);
    });
  },
});
