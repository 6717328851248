
import { defineComponent, onMounted, ref } from "vue";
import apiService from "@/core/services/ApiService";
import GlobalTable from "@/components/widgets/tables/GlobalTable.vue";
import DeleteDetails from "@/components/swal/DeleteDetails.vue";
import { useRoute, useRouter } from "vue-router";
import DropdownExport from "@/components/dropdown/DropdownExport.vue";

interface IPagination {
  page: number;
  total: number;
  rowsPerPage: number;
}

export default defineComponent({
  name: "user_details_table",
  components: { GlobalTable, DeleteDetails, DropdownExport },
  setup() {
    const route = useRoute();
    const router = useRouter();
    let item = ref();
    let change = ref<boolean>(false);
    const userID = ref();
    let updaterID = ref(null);
    let deleterData = ref({});
    let checkDeleter = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const currentSort = ref<string>("");
    let search = ref<string | null>(null);
    let getDataExist = ref<boolean>(true);
    let pagination = ref<IPagination>({
      page: 1,
      total: 1,
      rowsPerPage: 1,
    });

    let page = ref<number>(10);
    let perPage = ref<number>(1);
    let data = ref([]);

    const tableHeader = [
      {
        name: "Test ID",
        key: "id",
        sortable: false,
      },
      {
        name: "Date",
        key: "date",
        sortable: false,
      },
      {
        name: "Completed Sections",
        key: "sections_count",
        sortable: false,
      },
      {
        name: "Survey",
        key: "test",
        sortable: false,
      },
      {
        name: "Result",
        key: "result",
        sortable: false,
      },

      {
        name: "Actions",
        key: "actions",
      },
    ];
    onMounted(() => {
      getDetails(page.value, perPage.value);
    });

    function getDetails(perPage, page) {
      userID.value = Number(route.params.id)
        ? route.params.id
        : router.push({ name: "users" });

      search.value = null;
      loading.value = true;
      apiService
        .get(
          `/user/${userID.value}/details`,
          `?perPage=${perPage}&page=${page}`
        )
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data?.data;
            //pagination.value.page = resp.data?.meta.current_page;
            //pagination.value.total = resp.data?.meta.total;
            //pagination.value.rowsPerPage = resp.data?.meta.per_page;
            loading.value = false;
          } else if (resp.data?.code == 401) {
            router.push({ name: "sign-in" });
          }
        })
        .catch(function (error) {
          if (error.response && error.response.status == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    function searchDetails(perPage, page, query) {
      loading.value = true;
      apiService
        .get(
          `/user/${userID.value}/details/search`,
          `?query=${query}&perPage=${perPage}&page=${page}`
        )
        .then(function (resp) {
          if (resp.data.data) {
            data.value = resp.data.data;
            //pagination.value.page = resp.data.meta.current_page;
            //pagination.value.total = resp.data.meta.total;
            //pagination.value.rowsPerPage = resp.data.meta.per_page;
            loading.value = false;
          } else if (resp.data?.code == 401) {
            router.push({ name: "sign-in" });
          }
        });
    }

    const setItemsPerPage = (event) => {
      page.value = 1;
      perPage.value = event.target.value;
      if (search.value != null && search.value != "") {
        searchDetails(perPage.value, page.value, search.value);
      } else {
        getDetails(perPage.value, page.value);
      }
    };

    const currentPageChange = (val) => {
      page.value = val;
      //pagination.value.page = val;
      if (search.value != null && search.value != "") {
        searchDetails(perPage.value, val, search.value);
      } else {
        getDetails(perPage.value, val);
      }
    };

    const setSearchValue = (event) => {
      search.value = event.target.value.trim();
      if (!search.value?.trim()) {
        getDetails(perPage.value, page.value);
        return;
      }
      searchDetails(perPage.value, page.value, search.value);
    };

    const checkModal = (resp) => {
      if (resp.status == "updated") {
        let array = JSON.parse(JSON.stringify(data.value));
        let foundIndex = array.findIndex((x) => x.id == resp.data.id);
        array[foundIndex] = resp.data;
        data.value = new Proxy(array, {});
      } else if (resp.status == "created") {
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      } else if (resp.status == "deleted") {
        search.value = null;
        //pagination.value.page = resp.data?.meta.current_page;
        //pagination.value.total = resp.data?.meta.total;
        //pagination.value.rowsPerPage = resp.data?.meta.per_page;
        data.value = new Proxy(resp.data?.data, {});
      }
    };
    const existData = async (resp) => {
      getDataExist.value = false;
      if (resp) {
        getDataExist.value = await resp;
      }
    };
    const DeleteList = (id, code) => {
      checkDeleter.value = !checkDeleter.value;
      deleterData.value = {
        id: id,
        code: code,
      };
    };

    return {
      loading,
      tableHeader,
      data,
      setItemsPerPage,
      currentPageChange,
      pagination,
      perPage,
      page,
      search,
      setSearchValue,
      currentSort,
      checkModal,
      item,
      updaterID,
      checkDeleter,
      deleterData,
      change,
      DeleteList,
      getDataExist,
      existData,
    };
  },
});
